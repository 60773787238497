import React from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import useAuth from "src/hooks/useAuth";

const RoleGuard = ({ children, role }) => {
  const { user } = useAuth();

  if (user && role) {
    if (!user.role || (user.role && !role.includes(user.role)))
      return <Redirect to="/404" />;
  }

  return <>{children}</>;
};

RoleGuard.propTypes = {
  children: PropTypes.node
};

export default RoleGuard;
