import React, { Suspense, Fragment, lazy } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import LoadingScreen from "src/components/LoadingScreen";
import AuthGuard from "src/components/AuthGuard";
import GuestGuard from "src/components/GuestGuard";
import RoleGuard from "./components/RoleGuard";

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <RoleGuard role={route.role || null}>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </RoleGuard>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFoundView"))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/login",
    component: lazy(() => import("src/views/auth/LoginView"))
  },
  {
    path: "/app",
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: "/app",
        component: lazy(() => import("src/views/fiscalizacion/index"))
      },
      {
        exact: true,
        path: "/app/fiscalizacion",
        component: lazy(() => import("src/views/fiscalizacion/index"))
      },
      {
        exact: true,
        path: "/app/monitoreo",
        component: lazy(() => import("src/views/monitoreo/index")),
        role: "admin"
      },
      {
        exact: true,
        path: "/app/seguimiento",
        component: lazy(() => import("src/views/seguimiento/index")),
        role: "admin"
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "*",
    component: lazy(() => import("src/views/auth/LoginView"))
  }
];

export default routes;
